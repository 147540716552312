@import '../../../design-system//global.scss';
.order-title {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;

  color: $purple;
}

.draft-booking-wrapper {
  padding: 16px 5px;
  .items-table {
    margin-top: 8px;
  }
}

.action-footer {
  width: 100%;
  padding: 8px;
  > button {
    margin-right: 10px;
  }
}

.main-section {
  margin-bottom: 25px;
}

.action-section {
  padding: 16px;
  margin-bottom: 32px;
}
