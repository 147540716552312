.search-input-wrapper {
  background-color: #ededed;
  border-radius: 5px;
  .search-input {
    width: 100px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.5s ease-in-out;

    &.ant-select-focused {
      width: 400px;
    }
  }
}
.search-dropdown {
  width: 400px !important;
}

.label-wrapper {
  margin-bottom: 20px;
}
.label-title {
  width: 350px;
}
.tracking-result {
  .ant-divider-horizontal {
    margin: 0.5rem;
  }
  .tracking-info {
    display: flex;
    flex-direction: column;
  }
}
