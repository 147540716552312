.chart-wrapper {
  height: 300px;
  background-color: white;
}

.container{
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}

.iframe-container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
