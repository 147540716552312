.new-quote-modal-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &.padding-fix {
    padding: 0 20px 10px;
  }

  .party-icon {
    font-size: 30px;
    margin-left: 10px;
  }

  .new-quote-modal-header {
    width: 100%;
    margin: 30px 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content-box {
    max-width: 350px;
    margin-bottom: 30px;
    margin-top: 10px;

    .bidding-text {
      color: #0062ff;
      font-weight: 700;
    }
  }

  .modal-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .bidding-text-wrapper {
      width: 100%;
    }

    .modal-actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      > .back-btn {
        margin-right: 15px;
        margin-left: 15px;
      }
    }
  }

  @media screen and (max-width: 676px) {
    .modal-footer,
    .new-quote-modal-header {
      flex-direction: column;
    }

    .bidding-counter-wrapper {
      margin-top: 10px;
    }

    .content-box {
      text-align: center;
    }

    .modal-footer {
      .bidding-text-wrapper {
        text-align: center;
        margin-bottom: 20px;
      }

      .modal-actions {
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;

        .ant-btn {
          width: 180px;
          display: block;
          margin-bottom: 10px;
        }
      }
    }

    .ant-alert {
      .ant-alert-icon {
        display: none;
      }
    }
  }
}
