@import '../../../design-system/global.scss';
.po-actions-bar {
  margin-bottom: 25px;
  .search-wrapper {
    min-width: 200px;
  }
}

.purchase-orders-wrapper {
  height: 70vh;
  overflow-y: scroll;
  padding-right: 15px;

  .add-user-button {
    svg path {
      fill: $black;
    }
    &:disabled {
      svg path {
        fill: $gray_400;
      }
    }
  }
}
