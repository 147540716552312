@import '../../../design-system/global.scss';
.fees-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &__amount {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: center;

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &__amount-wrapper {
    padding: 16px;
    background-color: $light-yellow;
    border-radius: 8px;
  }

  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;

    &__title {
      justify-content: center;
    }

    &__amount-wrapper {
      padding: 10px;
      background-color: $light-yellow;
      width: fit-content;
    }

    &__amount {
      font-weight: 500;
      font-size: 14px;
    }
  }
}

.field-wrapper {
  width: 200px;

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
}
