@import '../../../design-system/Modal/modal.scss';

.shipment-creation-modal-wrapper {
  @extend .modal-wrapper;

  .delete-btn-fix {
    margin-top: 12px;
  }
}

.sh-onboarding-overview-screen {
  max-width: 70%;
  margin: 32px auto;
  text-align: center;
}

.sh-ai-form-screen {
  &__content {
    max-width: 70%;
    margin: 32px auto;
    text-align: center;
  }
}
