@import '../../design-system/global.scss';
.purchase-order-wrapper {
  border-right: solid 1px $blue_gray;
  padding-right: 10px;
}

.booking-wrapper {
  padding-left: 10px;
  position: relative;
}
