@import '../../../design-system/global.scss';

.duplicate-btn {
  color: $foreground-medium;
  font-size: 16px;
}

.duplicate-form-wrapper {
  padding-top: 20px;
}
