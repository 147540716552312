.empty-wrapper {
  height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .empty-proposal-illustration {
    width: 200px;
    height: 200px;
    background: url('../../../../assets/empty-proposal.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .empty-proposal-content {
    text-align: center;
  }
}
