@import '../../..//design-system/global.scss';

.chat-box {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 99;
  background-color: white;
  border-radius: 6px;

  &__header {
    height: 60px;
    background-color: $gray_50;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    border-radius: 6px 6px 0 0;
  }

  &__body {
    display: flex;
    height: calc(80vh - 130px);
    padding: 0 10px;
    overflow-y: auto;
    flex-direction: column;

    .message-item-wrapper {
      margin: 10px 0;
      display: flex;

      &.human {
        justify-content: flex-end;
      }

      .message-content {
        border-radius: 10px;
        padding: 10px;
        background: $gray_100;
        width: fit-content;

        &.human {
          background: $blue;

          & > .ant-typography {
            color: white;
          }
        }

        /* Apply styles to tables */
        .table-wrapper {
          width: 450px;
          overflow: auto;
          margin-top: 10px;

          table {
            width: 100%;
            border-collapse: collapse;
          }

          /* Apply styles to table headers */
          th {
            background-color: #f2f2f2;
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
          }

          /* Apply styles to table cells */
          td {
            border: 1px solid #ddd;
            padding: 8px;
          }

          /* Apply alternate background color to table rows */
          tr:nth-child(even) {
            background-color: #f9f9f9;
          }
        }
      }
    }
  }

  &__pills-wrapper {
    display: flex;
    overflow: hidden;
    height: 60px;
    padding: 0 10px;

    .pills-item {
      height: 30px;
      background: $gray_100;
      border-radius: 6px;
      margin: 5px;
      width: fit-content;
      padding: 8px;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
        background-color: $gray_300;
        opacity: 0.8;
      }
    }
  }

  &__pills-wrapper:hover {
    overflow-x: scroll;
  }

  &__footer {
    height: 70px;
    border-top: 2px solid $gray_50;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;

    &-input {
      margin-right: 15px;
    }
  }
}
