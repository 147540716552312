@import '../../design-system/global.scss';

.edit-shipment-actions {
  display: flex;
  margin: 40px 30px;
  justify-content: flex-end;
}

.edit-btn {
  color: $foreground-medium;
  font-size: 16px;
}

.shipment-edit-type-checkbox {
  padding: 5px 15px;
  background: $gray_100;
  border: 1px solid $gray_300;
  border-radius: 8px;
}
