.proposal-edit-wrapper {
  .header {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 40px;

    .user-gravatar {
      margin-right: 15px;
    }

    .info {
      display: flex;
      align-items: center;
    }

    .status {
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
