@import '../../../design-system/global.scss';

.invoice-timeline-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .timeline-item {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }

  .timeline-item-content {
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
      font-size: 18p;
      color: $gray_45;
      margin-top: 10px;
    }

    .item-heading {
      width: 100%;
      display: flex;
      align-items: center;

      h3 {
        margin: 0;
        margin-top: 2px;
        font-weight: 500;
        color: $gray_45;
      }

      .line {
        flex: 1;
        border-bottom: 1px solid $gray_45;
        padding: 0 20px;
        margin: 0 20px;
        opacity: 45%;
      }
    }
  }

  .timeline-item-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $white;
    margin-right: 10px;
    border: 1px solid $gray_45;

    .text {
      display: block;
      width: 22px;
      font-size: 16px;
      text-align: center;
      line-height: 20px;
      color: $gray_45;
    }
  }

  .completed {
    .timeline-item-icon {
      background-color: $white;
      background: $white url('../../../../assets/icons/checked-icon-blue.svg')
        no-repeat center;
      border-color: $blue_900;

      .text {
        visibility: hidden;
      }
    }

    .item-heading {
      h3 {
        color: $gray_600;
      }

      .line {
        border-color: $blue_900;
      }
    }
  }

  .active {
    .timeline-item-icon {
      background-color: $blue_900;
      border-color: $blue_900;

      .text {
        color: $white;
      }
    }

    h3 {
      color: $blue_900;
    }
  }

  & > div:last-child {
    .timeline-item-content {
      .item-heading {
        .line {
          display: none;
        }
      }
    }
  }
}
