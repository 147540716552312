@import '../../../design-system/global.scss';

.proposal-diff-wrapper {
  .added,
  .deleted,
  .updated {
    background-color: $red_200;
    padding: 5px;

    &.text-value {
      color: $red_700;
      font-weight: 600;
    }
  }

  &--split-view {
    display: flex;
    flex-direction: column;
    flex: 1;

    .split-view-row {
      display: flex;

      &--left,
      &--right {
        flex: 1;
        padding: 20px 10px;

        &.p-0 {
          padding: 0;
        }
      }

      &--left {
        border-right: 1px solid $blue_gray;
      }
    }

    .header {
      height: 50px;
      font-weight: bold;
      padding-left: 10px;
      border-top: 1px solid $blue_gray;
      display: flex;
      align-items: center;

      &.old-view {
        background-color: $green_200;
        color: $green_900;
      }

      &.new-view {
        background-color: $red_200;
        color: $red_700;
      }
    }
  }
}
