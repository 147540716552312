@import '../../../design-system/global.scss';

@mixin textStyles {
  color: $blue_900;
  margin-right: 5px;
  font-size: 14px;
  line-height: 14px;
}

.shipment-bidding-countdown {
  width: 100%;
  padding: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background_blue;
  border-radius: 5px;

  &:hover {
    background-color: $blue_100;
  }

  &__danger {
    width: 100%;
    padding: 2px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $red_200;
    border-radius: 5px;
    color: $red_700;
    font-size: 12px;
    &:hover {
      background-color: $red_300;
    }
  }
  &__fit {
    width: fit-content !important;
    padding: 10px 0% 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $background_blue;
  }

  &__fit > .bidding-counter-wrapper,
  & > .bidding-counter-wrapper,
  &__danger > .bidding-counter-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    &.cursor-pointer {
      cursor: pointer;
    }
  }

  &__fit > .bidding-counter-wrapper .ant-statistic .ant-statistic-content,
  & > .bidding-counter-wrapper .ant-statistic .ant-statistic-content,
  &__fit .bidding-text,
  & .bidding-text {
    @include textStyles;
  }

  &__danger > .bidding-counter-wrapper .ant-statistic .ant-statistic-content {
    @include textStyles;
    color: $red_700;
  }
  &__danger > .bidding-counter-wrapper .bidding-text {
    margin-right: 5px;
  }

  &__fit .dropdown-icon,
  & .dropdown-icon {
    font-size: 10px;
    color: $blue_900;
  }
  &__danger .dropdown-icon {
    font-size: 10px;
    color: $red_700;
  }
}

.bidding-counter__dropdown {
  min-width: 200px !important;
  width: 220px;
  left: 45vw !important;
}
.Bidding-counter__dropdown__dashboard {
  align-items: center;
}
.bidding-dashboard {
  width: 140px;
  .bidding-text,
  .bidding-counter-wrapper .ant-statistic .ant-statistic-content,
  .dropdown-icon {
    font-size: 12px;
    line-height: 15px;
  }
}
