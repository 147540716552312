.booking-parties-card {
  &.hightligh-animation {
    animation: highlight 2000ms ease-out;
  }

  .booking-parties-wrapper {
    height: 390px;
    overflow-y: auto;
    padding-right: 10px;
  }
}

.booking-item-wrapper {
  // box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
  //   0px 1px 2px rgba(16, 24, 40, 0.06);
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border: 1px solid rgba(16, 24, 40, 0.1);
  border-radius: 4px;
  margin-bottom: 20px;

  .item-row {
    display: flex;
  }

  .item-header {
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;

    .company-text {
      font-size: 24px;
    }
  }
}

.booking-party-preview {
  display: flex;
  flex-direction: column;

  &--header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}

@keyframes highlight {
  0% {
    background-color: white;
  }
  50% {
    background-color: rgb(190, 188, 188);
  }
  100% {
    background-color: white;
  }
}
