.filters-form-wrapper {
  .location-search-feild {
    width: 100%;
    margin-bottom: 6px;
    flex: 1;
  }
  .filter-values {
    height: 300px;
    overflow-y: auto;
  }

  .filter-input-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    &.vertical {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    .input-space-wrapper {
      width: 150px;
      margin: 6px 0 6px 0;
    }
  }
}

.date-filter-form-wrapper {
  display: flex;
  flex-direction: column;

  .date-range-input {
    width: 250px;
  }
}

.filter-footer-actions {
  display: flex;
  justify-content: flex-end;
}

.filter-btn-fixed {
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  align-items: center;

  .btn-text {
    margin-right: 10px;
  }
}

.filter-btn {
  .btn-text {
    margin-right: 10px;
  }
}
