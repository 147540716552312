@import '../../../design-system/global.scss';

.notification-wrapper {
  .ant-badge {
    .anticon {
      font-size: 24px;
      color: $foreground-low;
      cursor: pointer;
    }
  }
}
.error,
.loading,
.fetching {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-dropdown {
  width: 35vw;
  height: 72vh;

  .ant-dropdown-menu-item {
    height: 120px;
  }

  &__empty {
    height: calc(70vh - 115px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__scroll-wrapper {
    height: calc(70vh - 115px);
    overflow: auto;
  }
  .notification-card {
    height: 120px;
    width: 32vw;
    max-width: 100%;
    display: flex;
    justify-content: space-between;

    .notification-text {
      flex: 0 0 75%;
      overflow: hidden;
      .notification-reference,
      .notification-message,
      .notification-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .notification-status {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      justify-content: center;

      .unread-icon {
        .ant-badge-status-dot {
          width: 10px;
          height: 11px;
        }
      }
    }
  }
}

.notification-see-more-action {
  display: flex;
  justify-content: center;
  gap: 0.5vw;
  padding: 10px 0;
}

.settings-menu {
  .ant-dropdown-menu-item {
    padding: 0% !important;
  }

  .notification-menu-button {
    width: 100%;
  }
}

.notification-header {
  border-bottom: 1px solid $blue_gray;
  padding: 20px 10px;
}
