.auth-form {
  &__remember-me {
    justify-content: space-between;
  }

  &__login-btn,
  &__login-btn-external {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;

    svg {
      margin-right: 10px;
    }
  }

  .items-wrapper {
    margin: 15px 0;
  }
}
