.modal-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  .modal-content {
    text-align: center;
    margin-bottom: 32px;
  }
}
