@import '../../../design-system/global.scss';

.items-table {
  table {
    //border-spacing: 0 10px;

    thead {
      background: $light-yellow;
      opacity: 0.75;
      tr th {
        background: $light-yellow;

        &:first-child {
          border-radius: 8px 0 0 8px;
        }
        &:last-child {
          border-radius: 0 8px 8px 0;
        }
      }
    }

    .ant-table-cell-row-hover {
      background: $card-success !important;
    }
  }

  &__row__qte:not(th) {
    border-left: $blue_gray 1px solid;
  }
}

.poi-slug-text {
  width: fit-content;
}

.poi-ref-text {
  width: 120px;
}
