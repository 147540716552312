@import '../../design-system/global.scss';

.header-wrapper {
  width: 100%;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  z-index: 10;
  padding-inline: 25px;
  .header-row {
    .ant-avatar {
      background-color: $foreground-low;
    }
  }
}

// the onClick listeners are not on the list items but rather in divs within but the list items has a padding by default and clicking in the padding doesn't trigger it
.create-dropdown {
  .ant-dropdown-menu-item {
    padding: 0 !important;
  }
  .create-dropdown-item {
    display: block;
    padding: 5.5px 12px;
  }
}

.messages-btn {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
}
