@import '../../design-system/global.scss';

.login-container {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .ant-form-item {
    /* find a way to edit this in config */
    margin-bottom: 10px;
  }
}

.login-wrapper {
  width: 100%;
  height: 100%;

  &__form {
    background: $white;
    width: calc(100% - 40px);
    height: 100%;
    padding: 0 20px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .auth-form {
      width: 100%;
      max-width: 350px;
    }
  }

  &__illustration {
    background: $background_medium;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > img {
      width: 60%;
      margin-bottom: 40px;
    }

    &__caption-text,
    &__caption-text span {
      font-size: 21px;
      text-align: center;
    }

    &__logos {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      > img {
        height: 30px;
        margin: 0 15px;
      }
    }

    @media only screen and (max-width: 1050px) {
      &__caption-text,
      &__caption-text span {
        font-size: 18px;
      }

      &__logos {
        margin-top: 10px;
        > img {
          height: 25px;
          margin: 0 5px;
        }
      }
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
}
