@import '../../../design-system//global.scss';
.info-wrapper {
  display: grid;
  padding-right: 16px;

  &__label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: $gray_600;
  }

  &__info {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    word-break: break-word;
    display: flex;
    align-items: center;
    color: $primary;
  }
}
.cargo-description {
  white-space: pre-line;
}

.units-section {
  border: dashed 1px $gray_400;
  padding: $fixed-margin-sm;
  border-radius: 8px;
}

.section-toggle-lable {
  &.ant-typography {
    margin-bottom: 0;
    margin-top: 28px;
    margin-bottom: 8px;
  }

  span {
    margin-right: 5px;
  }
}
