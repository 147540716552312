.proposal-qoutes-table {
  width: 100%;

  .total-cost {
    color: #4961f6;
    font-size: 10px;
    width: 100px;
  }

  .submited-by-text {
    font-size: 12px;
  }
}
