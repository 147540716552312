@import '../../../design-system//global.scss';
.tracking-wrapper {
  height: 280px;
  overflow: auto;
  padding: 5px;

  .tracking-item {
    padding: 0px 7px;
    margin-bottom: 10px;

    &-title {
      font-size: 12px;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      min-width: 180px;

      &:nth-child(1) {
        padding-top: 0;
      }

      &-descripton {
        color: $gray_600;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .event-date {
      font-size: 10px;
    }
  }

  .activate-tracking {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// .ant-timeline-item.tracking-active {
//   .ant-timeline-item-tail {
//     border-inline-start: 2px solid $blue_900;
//     height: calc(100% - 20px);
//   }
// }
