@import '../../design-system/global.scss';

.shipment-type-title {
  font-weight: 600;
  margin: 0;

  &.lcl {
    color: #0062ff;
  }
  &.ltl {
    color: #ff9900;
  }
  &.ftl {
    color: #46b96b;
  }
  &.fcl {
    color: #46a0b9;
  }
  &.air-cargo {
    color: #46a0b9;
  }
  &.courier {
    color: #7c46b9;
  }
}

.shipment-bidding-counter-wrapper,
.shipment-qoutes-action-header {
  margin: 0 0 20px;
}

.slim-card-wrapper {
  border-radius: 8px;
  border: 0.5px solid $blue_gray;
}

.proposal-cost-box {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .amount-value-box {
    padding: 8px;
    border-radius: 4px;
    background: $purple;
    text-align: right;

    &__total-converted {
      color: $white;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }

    &__total-brut {
      color: $purple_50;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &.updated {
    background-color: #fff;

    .amount-value-box {
      background: $red_700;
    }
  }
}

.proposal-modal-loader-wrapper {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
