.document-extractor-modal-wrapper {
  position: relative;

  .ant-modal-content {
    padding: 12px;
  }

  &.align-top {
    top: 5px;
  }
  .right-panel {
    overflow-x: hidden;
  }

  .loading {
    width: 100%;
    height: 350px;
  }

  .modal-sub-title {
    font-weight: 400 !important;
  }

  .document-extractor-wrapper {
    height: 93vh;

    .pdf-viewer {
      height: 100%;
      overflow: hidden;
    }
  }
}

.minimized-upload {
  &--wrapper {
    background-color: white;
    width: 289px;
    height: 119px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0px -4px 6px -2px #1018281a;
    box-shadow: 0px -3px 25px -4px #10182826;
    z-index: 999;
  }

  &--header {
    width: 100%;
    height: 40px;
    background-color: #4961f6;
    display: flex;
    align-items: center;
    color: white;
    font-weight: 600;
    padding: 0 10px;
    border-radius: 8px 8px 0 0;
    justify-content: space-between;
  }

  &--sub-header {
    width: 100%;
    height: 32px;
    background-color: #f5f7fb;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  &--sub-header {
    width: 100%;
    height: 32px;
    background-color: #f5f7fb;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  &--items-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    &.uploaded {
      cursor: pointer;
    }

    .file-title {
      margin-left: 10px;
    }
  }
}
