.slug-tag {
  width: 32px;
  height: 10px;
  border-radius: 8px;
  margin-right: 4px;
}

.slug-tags-container {
  display: flex;
}
