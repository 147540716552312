@import '../../../design-system/global.scss';
.forwarders-table {
  margin-top: 30px;
  .ant-table-expanded-row {
    .ant-table-cell,
    .ant-table-cell:hover {
      padding: 20px;
      background-color: $white;
    }
  }

  & .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin-inline: -20px -16px;
  }

  .selected-users {
    color: $blue_900;
  }
}

.users-search {
  width: 100%;
}
