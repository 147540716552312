.shipment-finance-wrapper {
  .invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }

  .invoice-empty-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .pdf-icon-filled {
    font-size: 20px;
  }
}
