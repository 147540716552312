@import '../../../design-system/global.scss';

.shipment-status-wrapper {
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer-status,
    .footer-date {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      div.ant-typography {
        color: $gray_600;
        margin-top: 10px;
      }
    }
  }
}
