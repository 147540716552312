.upload-document-wrapper {
  padding: 20px 0;
  margin: 20px 0 0;
}

.upload-file-wrapper {
  width: 100%;
  height: 100px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .camera-icon {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

.upload-btn {
  margin-top: 20px;
}

.documents-type-selector {
  width: 100%;
  margin-bottom: 10px;
}
