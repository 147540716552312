.user-story-time-line-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 0;

  .time-line-label {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #6f6f6f;
  }

  .time-line-items-wrapper {
    display: flex;

    .story-item {
      width: 80px;
      height: 6px;
      border-radius: 4px;
      background-color: #f5f7fb;
      margin: 0 5px;

      &.active {
        background-color: #4961f6;
      }
    }
  }
}
