@import '../../../design-system/global.scss';
.quotes-table {
  .quotes-row {
    cursor: pointer;
    .shipment-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    .bidding-count {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
