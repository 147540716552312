@import '../../design-system/global.scss';
.dynamic-delete-order-button {
  margin-top: 30px;
}

.contract-toolbar {
  background-color: white;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: fixed;
  z-index: 10;
  width: 100%;
  bottom: 0;

  &__title {
    color: $secondary;
    margin: unset !important;
  }
}

.accept-btn {
  svg path {
    fill: $green_900;
    color: $green_900;
  }
}
