.files-preview-wrapper {
  flex-wrap: wrap;
}

.loader-content-wrapper {
  padding: 10px;
}

.highlighted-row {
  animation: highlight 1.5s ease-out;
}
