@import '../../../design-system/global.scss';
.new-quote-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bidding-text-wrapper {
  padding: 10px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

  .bidding-text {
    color: $blue_900;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
  }
}
