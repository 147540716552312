@import '../../../design-system/global.scss';

.pdf-matchin-wrapper {
  height: 100%;

  .invoice-table-wrapper {
    height: 100%;
    border-right: 1px solid $blue_gray;
  }

  .invoice-matching-table-wrapper {
    width: 100%;
    overflow: auto;
  }
}
