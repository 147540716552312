@import './app/design-system/global.scss';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7faff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-wrapper {
  margin-bottom: 20px;

  table {
    border: solid 1px #ccd0d3;
    border-radius: 8px;
  }
  thead > tr > th,
  thead > tr > td {
    background-color: #f7faff !important;
  }

  &--no-border {
    table {
      border: none;
    }

    thead > tr > th,
    thead > tr > td {
      background-color: #f7faff !important;
      border-bottom: none !important;
    }

    tbody > tr:not(.ant-table-measure-row) > td {
      border-bottom: 1px solid #ccd0d3 !important;
    }
  }
}

.ant-input-number-affix-wrapper,
.ant-input-number {
  width: 100% !important;
}

// width
.full-width {
  width: 100%;
}

// margin
.m-0 {
  margin: 0 !important;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.my-1 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mx-1 {
  margin-right: 8px;
  margin-left: 8px;
}

.mx-2 {
  margin-right: 16px;
  margin-left: 16px;
}

.my-2 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mt-2 {
  margin-top: 16px;
}

.mb-2 {
  margin-bottom: 16px;
}

.m-3 {
  margin: 24px;
}

.mx-3 {
  margin-left: 24px;
  margin-right: 24px;
}

.my-3 {
  margin-top: 24px;
  margin-bottom: 24px;
}

// padding
.p-0 {
  padding: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.p-2 {
  padding: 16px;
}

.px-2 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.p-3 {
  padding: 24px;
}

.px-3 {
  padding-left: 24px;
  padding-right: 24px;
}

.py-3 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.element-center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.font-light {
  font-weight: 400;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.text-center-sm {
  @media screen and (max-width: 480px) {
    text-align: center;
  }
}

.ant-picker {
  display: flex;
}

// PO and bookings table style
.po-table {
  table {
    border-spacing: 0 10px;
  }
}
.po-table,
.bookings-table {
  table {
    > thead > tr > th,
    > thead > tr > td {
      background: #f5f7fb;
      &:first-child {
        border-radius: 8px 0 0 8px;
      }
      &:last-child {
        border-radius: 0 8px 8px 0;
      }
    }
  }
  .row-sub-text {
    font-weight: 400;
    font-size: 12px;

    color: $foreground-low;
  }

  .row-po-text {
    color: $purple;
  }

  tr.po-row,
  tr.bookings-row {
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 16px;

    > td {
      padding-bottom: 1em;

      &:first-child {
        border-radius: 16px 0 0 16px;
      }
      &:last-child {
        border-radius: 0 16px 16px 0;
      }
    }
    &-expanded {
      &__sub,
      &__sub:hover {
        background: $white;
        .ant-table-wrapper:only-child .ant-table {
          margin-inline: 0px !important;
          table {
            border-spacing: 0px;

            > thead > tr > th {
              background: $light-yellow;
              &:first-child {
                border-radius: 8px 0 0 8px;
              }
              &:last-child {
                border-radius: 0 8px 8px 0;
              }
            }
          }
        }

        > td {
          border-bottom: solid 1px $foreground-low !important;
          border-top: solid 1px $foreground-low;
          background: $white;

          &:first-child {
            border: solid 1px $foreground-low;
            border-radius: 16px;
            margin-inline: 0px;
          }
        }
      }
    }
  }
}

.empty-table-wrapper {
  padding: '50px 0';
}

.app-theme-wrapper {
  .ant-btn-default {
    background-color: $toke_purple_50 !important;
    border: 1px solid $toke_purple_50;
  }

  .ant-btn-default.ant-btn-dangerous {
    background-color: $token_red_100 !important;
    border: 1px solid $token_red_100;
  }

  /* Artificially add selector specificity to fix button icon placement issue due to font family differences */
  a.ant-btn,
  button.ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
