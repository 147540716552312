@import '../../../design-system/global.scss';

.shipment-header-wrapper {
  width: 100%;
  margin-bottom: 20px;
  height: fit-content;
   box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);

  .shipment-info-wrapper {
    background: $secondary;
    width: 100%;
    height: 90px;
    /* Shadow/shadow-low */
  
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    .bin-icon {
      font-size: 15px;
      margin-right: 5px;
    }

    .arrow-icon {
      font-size: 15px;
      margin: 0 30px;
    }

    .action-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 20px;
    }

    .shipment-location-wrapper {
      display: flex;
      margin-left: 70px;
    }

    .left-wrapper,
    .right-wrapper {
      width: 60%;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      // justify-content: flex-start;
    }
    .right-wrapper {
      justify-content: flex-end;
    }
  }

  .shipment-tag-wrapper {
    background: $secondary;
    width: 100%;  
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    padding: 20px;

  }


  .shipment-country-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .arrow-icon {
      font-size: 15px;
      margin: 0 30px;
      font-weight: bold;
    }

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;

      > .ant-typography {
        margin: 0;
      }
    }
  }

  .user-gravatar-wrapper {
    display: flex;
    align-items: center;
    position: relative;

    .user-gravatar {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      margin-right: -5px;
    }
  }
}

.bookmark-btn {
  &.isActive > svg {
    color: $primary;
  }
}
