@import '../../../design-system/global.scss';
.container-header-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.container-table {
  .time-line-wrapper {
    width: 60%;
    .event-title__checked {
      color: $blue_selected;
    }
  }
}
