@import '../../../design-system/global.scss';
.input-container {
  gap: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}



.tags-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.add-another-btn {
  margin-top: 10px;
}

.close-button {
  margin-top: 10px;
  color: $gray_45;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.input-container {
  display: flex;
  align-items: center;
  gap: 2px;
  border: solid 1px $blue_gray;
  border-radius: 4px;
}

.tag-inside-input {
  background-color: $gray_400;
  padding: 4px;
  border-radius: 4px;
}

.input-inside {
  border: none;
  outline: none;
  width: 100%;
}

.form-header {
  display: flex;
  margin-bottom: 10px;
  background-color: $gray_100;
  padding: 6px;
  border-radius: 4px;
}



.tags-container{
  background-color: $white;
  width: 800px;
  max-height: 800px;
  padding: 16px;
  padding-top: 8px;
  z-index: 1;
}



.dropDown-header{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
  align-items: self-end;
}

.dropDown-header .ant-col:last-child {
  display: flex;
  justify-content: flex-end;
}

.dropdown-container{
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  z-index: 1000;
}


