.shipment-info-wrapper {
  height: 390px;
  overflow-y: auto;
  padding-right: 10px;

  .item-wrapper {
    display: flex;
    align-items: center;

    .item-label {
      flex: 1;
      font-weight: bold;
      margin-right: 10px;
    }
    .item-content {
      flex: 2;
    }
  }
  .mbl-number {
    display: flex;
    align-items: center;
  }
}
