@import '../../../design-system/global.scss';
.dashboard-card-table {
  .ant-table {
    .ant-table-thead {
      .ant-table-cell {
        background: none;
        font-weight: 400;
        color: $gray_500;
        border: none;
        &::before {
          display: none;
        }
      }
    }
  }
}
