@import '../../../design-system/global.scss';

.btn-wrapper {
  background-color: #dae9fe;
  button {
    width: 100%;
  }
}
.collaborator-btn-wrapper {
  margin: 10px 5px;
}
