@import '../../../design-system/global.scss';
.pdf-file {
  svg {
    fill: $red_900;
    color: $red_900;
  }
}
.xcl-file {
  svg {
    fill: $green_900;
    color: $green_900;
  }
}

.po-creation-screen {
  max-width: 75%;
  margin: 20px auto;

  &_upload-btn {
    margin: 20px 0;

    .ant-upload-wrapper .ant-upload-drag {
      border: 1px solid $token_gray_200;
      background: $white;
      padding: 20px;
    }
  }

  &_manual {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
