@import '../../../design-system/global.scss';

.invoice-table-card-content,
.invoice-chart {
  margin-bottom: 16px;
  filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1))
    drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06));

  .invoice-card-table {
    overflow: auto;
    max-height: 200px;

    @media (min-width: 900px) {
      max-height: 250px;
    }

    @media (min-width: 1200px) {
      max-height: 330px;
    }

    @media (min-width: 1600px) {
      max-height: 200px;
    }
  }
  .invoice-card-title {
    padding: 0 0 10px 10px;
  }
  .ant-card-body {
    padding: 0px 2px 20px 2px;
  }
  .invoice-card-table-row {
    color: $gray_600;
  }
  @media (min-width: 768px) {
    min-height: 300px;
  }
  @media (min-width: 900px) {
    min-height: 350px;
  }

  @media (min-width: 1200px) {
    min-height: 430px;
  }

  @media (min-width: 1600px) {
    min-height: 300px;
  }
}

.card-wrapper {
  .invoice-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .card-title {
      &__received {
        color: $purple_600;
      }
      &__to-be-collected {
        color: $red_700;
      }
    }
  }
}
