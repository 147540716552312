@import '../../../design-system/global.scss';

.share-document-modal-container {
  padding: 15px 0;

  .file-access-preview-wrapper {
    display: flex;
    align-items: center;
    margin: 15px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid $gray_300;

    .file-access-icon {
      margin-right: 10px;
    }

    .file-access-preview-content {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }
  }

  .file-access-select {
    width: 100%;

    > .ant-select-selector {
      border: none;
      box-shadow: none;
      outline: none;
    }

    .ant-select-selection-item {
      color: $gray_600;
    }
  }
}
