.checklist-dropdown-overlay {
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin: 5px 0;
    }
  }
}

.checklist-wrapper {
  height: 300px;
  overflow-y: auto;
  padding: 15px;
}
