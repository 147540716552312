.proposal-cards-wrapper {
  padding-bottom: 20px;
  max-width: 100vw;
  margin-top: 20px;
}

.empty-proposal-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.shipment-quotes-wrapper {
  width: 100%;
}

.filter-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  &.vertical {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .input-space-wrapper {
    width: 200px;
    margin: 6px 0 6px 0;
  }
}
