@import '../../../design-system/global.scss';
.order-header {
  border-radius: 8px;
  background: $card-primary;
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
}

.booking-footer {
  background: $card-primary;

  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}
