@import '../../design-system/global.scss';

.create-shipment-by-tracking {
  .ant-modal-body {
    padding: 0rem 1rem 1rem 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tracking-input-content {
    .select-input {
      .ant-select-selection-placeholder {
        font-size: 0.95rem;
      }
      .ant-select-selection-search {
        align-items: flex-start;
        min-height: 3.5rem;
      }

      .ant-select-selection-placeholder {
        top: 25%;
      }
      .ant-select-selection-overflow-item {
        align-self: baseline;
      }
      width: 500px;
      .ant-select-arrow {
        display: none;
      }
    }
    .tracking-tag {
      margin-inline-end: 4;
      margin: 2px;
    }
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  .banner-wrapper-invalid,
  .banner-wrapper-existing {
    padding: 16px 20px;
    border-radius: 5px;
    margin-top: 24px;

    .tracking-numbers-list {
      margin-top: 0.75rem;
      max-height: 50px;
      overflow-y: auto;

      .bullet-point {
        list-style-type: disc;
      }
    }
  }

  .banner-wrapper-invalid {
    background-color: $red_200;

    .ant-typography {
      color: $token_red_800;
    }
  }

  .banner-wrapper-existing {
    background-color: $toke_purple_50;

    .ant-typography {
      color: $toke_purple_900;
    }
  }

  .container-shipments-wrapper {
    width: 400px;
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 24px;

    .tracking {
      display: flex;
      flex-direction: column;
    }
  }

  .footer {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin: 24px 0;
  }
}
