@import '../../design-system/global.scss';
.shipment-form-wrapper {
  @media screen and (max-width: 992px) {
    .dynamic-delete-button {
      margin-top: 28px;
    }
  }
}

.unit-dimensions-checkbox {
  margin: 16px 0px;
}

.shipment-type-checkbox {
  padding: 5px 15px;
  background: $gray_100;
  border: 1px solid $gray_300;
  border-radius: 8px;
}

.units-footer {
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;

  &__wrapper {
    margin: 10px 20px;
    min-width: 120px;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &__amount {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: center;

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &__amount-wrapper {
    padding: 16px;
    background-color: $light-yellow;
    width: fit-content;
  }

  &__title.light {
    color: $token_secondary;
    justify-content: end;
    margin-bottom: 10px;
  }

  &__wrapper.transparent {
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
    margin: 0;
    margin-left: 30px;
  }

  &__amount-wrapper.transparent {
    background-color: transparent;
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }

  &__amount.transparent {
    width: auto;
  }

  @media screen and (max-width: 992px) {
    &__wrapper {
      margin: 10px 0;
    }

    &__amount-wrapper {
      padding: 10px;
      background-color: $light-yellow;
      width: fit-content;
    }

    &__amount {
      font-weight: 500;
      font-size: 14px;
    }
  }
}

.shipment-spinning-wrapper {
  background: white;
  width: 30vw !important;
  height: 20vh !important;
  top: 10% !important;
  left: 35% !important;
  border-radius: 10px;
}

.bidding-time-picker {
  &.ant-picker-dropdown .ant-picker-time-panel-column::after {
    display: none;
  }
}
