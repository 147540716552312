.invoice-wrapper {
  height: calc(100vh - 100px);
  padding: 20px;
  position: relative;

  .close-icob-button {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 999;
  }
}

.invoice-error-screen-wrapper {
  height: 60vh;
}

.invoice-details {
  margin-bottom: 10px;

  &--row {
    min-height: 60px;
  }
}
