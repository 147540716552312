@import '../../../design-system/global.scss';
.content-card,
.list-card {
  margin-top: 10px;
  height: 60vh;
  overflow: auto;
  .ant-card-body {
    padding: 0px;
  }

  .shipment-message-wrapper {
    :hover {
      cursor: pointer;
      background-color: $gray_50;
    }
    &__selected {
      background-color: $blue_100;
      padding-left: 10px;
      .date-time {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 10px;
      }
    }
    .message-item {
      margin-left: 10px;
    }
    .date-time {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 10px;
    }
  }
  .shipment-messages-tabs {
    path {
      fill: $gray_600;
    }
    .ant-tabs-tab-active {
      path {
        fill: $blue_selected;
      }
    }
  }
}
.content-card {
  .ant-card-body {
    padding: 15px;
  }
}
.message-item {
  margin-left: 10px;
  .ant-list-item-meta-description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.header-card {
  height: 75px;
  .shipment-icon {
    font-size: x-large;
  }
  .header-content {
    margin-left: 10px;
    .shipment-reference {
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  h5 {
    margin: 0px;
  }
  .search-field {
    text-align: center;
    .search-icon svg {
      fill: $gray_400;
    }

    .ant-input-affix-wrapper {
      background-color: $light_blue;
      border: solid 1px transparent;
      border-radius: 15px !important;
      .ant-input {
        background-color: $light_blue;
      }
    }
    .ant-input-group-addon {
      display: none;
    }
  }
}
.illustration-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.shipment-messages-see-more-action {
  display: flex;
  justify-content: center;
  gap: 0.5vw;
  padding: 10px 0;
}
