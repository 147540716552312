@import '../../design-system/global.scss';
.proposal-page {
  position: relative;

  &__container {
    background-color: $white;
    width: 90%;
    margin: 0 auto;
    padding: 40px 20px;
    height: 100%;

    @media screen and (max-width: 480px) {
      width: 100%;
      padding: 0;
    }
  }

  &__fixed-header {
    background-color: $primary;
    padding: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;

    &__title {
      color: $secondary;
      margin: unset !important;
    }
  }

  &__footer {
    padding-left: 40px;
    padding-right: 40px;
  }

  &__diff-viewer {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    padding: 0 40px;
  }
}

.modal-content-input {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ant-upload-list-item-name {
  flex: unset !important;
}

.ant-upload-list-item-done {
  color: $green_900;
  font-weight: 500;
  .ant-upload-icon .anticon svg {
    color: $green_900;
  }
}

.proposal-form-wrapper {
  &.hightligh-animation {
    .card-wrapper {
      animation: highlight 2000ms ease-out;
    }
  }

  @media screen and (max-width: 992px) {
    .dynamic-delete-button {
      margin-top: 28px;
    }
  }
}

.create-new-proposal-modal {
  @media screen and (max-width: 480px) {
    align-items: center;
    display: flex;
    flex-direction: column;

    .ant-btn {
      margin-top: 10px;
    }
  }
}

@keyframes highlight {
  0% {
    background-color: white;
  }
  25% {
    background-color: rgb(220, 220, 220); /* A slightly darker gray tone */
  }
  50% {
    background-color: rgb(201, 200, 200); /* A subtle gray tone */
  }
  75% {
    background-color: rgb(220, 220, 220); /* Back to slightly darker gray */
  }
  100% {
    background-color: white;
  }
}
