.shipment-details-comparison-wrapper {
  .comparison-wrapper {
    display: flex;

    .left-wrapper,
    .right-wrapper {
      flex: 1;
      border: 1px solid #dddddd;

      .comparison-header {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f5f7fb;
        padding: 10px;
      }
    }

    .right-wrapper {
      .comparison-header {
        background: #f7f4fd;
      }
    }
  }
}
