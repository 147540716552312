@import '../../../design-system/global.scss';

.message-wrapper {
  display: flex;
  margin-top: 30px;

  &.active {
    flex-direction: row-reverse;
  }

  .message-item {
    display: flex;
    padding: 0 10px;

    .user-gravatar {
      margin-right: 5px;
      align-items: flex-start;
    }
  }
  .message-content {
    display: flex;
    flex-direction: column;
    background: $gray_100;
    border-radius: 0px 20px 20px 20px;
    padding: 20px;
    max-width: 450px;

    .info-wrapper {
      display: flex;
      margin-bottom: 5px;

      .user-name,
      .message-date {
        margin-right: 10px;
      }

      .user-name {
        color: $gray_600;
      }

      .message-date {
        color: $gray_400;
      }
    }

    .user-message,
    .user-message-link > a {
      color: $file_link;
      white-space: pre-wrap;
    }

    .user-message-link {
      margin-top: 5px;
      > a {
        .pin-icon {
          margin-right: 5px;
        }
        text-decoration: underline;
      }
    }

    &.active {
      background: $blue;
      border-radius: 20px 0px 20px 20px;

      .user-message,
      .user-message-link > a {
        color: $white;
      }

      .user-name,
      .message-date {
        color: $gray_300;
      }
    }

    &.chatbot {
      background: $purple;
    }
  }
}

.loader {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: block;
  margin: 10px;
  position: relative;
  color: $white_300;
  box-sizing: border-box;
  position: relative;
  background: $white_300;
  box-shadow: -12px 0$white_300, 12px 0$white_300;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;

  &-text {
    color: $purple;
    margin-bottom: 10px;
  }
}

@keyframes shadowPulse {
  33% {
    background: $white_300;
    box-shadow: -12px 0 $purple, 12px 0$white_300;
  }
  66% {
    background: $purple;
    box-shadow: -12px 0$white_300, 12px 0$white_300;
  }
  100% {
    background: $white;
    box-shadow: -12px 0$white_300, 12px 0 $purple;
  }
}
