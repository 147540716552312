@import '../../../design-system/global.scss';
.progress-wrapper {
  margin: 0px 8px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  .progress-indicator {
    display: flex;
    flex: 4;
    padding: 8px;
    justify-content: center;

    &:not(:last-child) {
      border-right: 0.25px solid $gray_300;
    }

    &--activated {
      background-color: $card-success;
      border-bottom: 3px solid $green_900;
    }

    &--declined {
      background-color: $card-failure;
      border-bottom: 3px solid $red_900;

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $red_900;
        border-color: $red_900;
      }
    }
  }
}
