@import '../../design-system/global.scss';
.side-menu {
  z-index: 20;
  &.ant-layout-sider-collapsed {
    width: 60px !important;
    flex: 0 0 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  }
  .ant-menu:not(.ant-menu-sub) {
    margin-top: 60px;
  }
}
.ant-menu-item {
  svg {
    transform: scale(1.35);
    fill: $foreground-low;
    path {
    }
  }
}
.ant-menu-item-selected {
  fill: $primary;
  svg path {
  }
  .ant-menu-title-content {
    color: $primary;
    font-weight: 600;
  }
}
.messages-icon {
  svg path {
    fill: $foreground-low;
  }
}

.collapse-btn {
  font-size: 16px;
  height: 64px;
  width: 100% !important;
  svg path {
    fill: $foreground-low;
  }
  .beebolt-icon {
    path {
      fill: $black;
    }
  }
}

.menu-bottom {
  max-width: 100%;
  width: 100%;
  position: absolute;
  bottom: 60px;
}
