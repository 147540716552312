@import '../global';
.card-wrapper {
  background-color: $white;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 85vh;

  //
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  margin: $fixed-margin;

  //
  overflow-y: auto;

  // shadow
  filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1))
    drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06));

  .card-header {
    justify-content: space-between;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 7;
    background-color: $white;
    margin-bottom: 16px;

    &__title-wrapper {
      display: flex;
      flex-direction: column;
    }

    &__title {
      margin: unset;
      font-size: 20px;
    }

    &__sub-title {
      color: #6f6f6f !important;
      margin-top: 20px;
    }

    @media screen and (max-width: 480px) {
      flex-direction: column;

      &__title {
        margin: 15px 0;
      }
    }
  }
  .messages-card-header {
    justify-content: space-between;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 7;
    background-color: $white;

    .card-header__sub-title {
      color: #6f6f6f !important;
      margin-top: 5px;
    }
    .messages-divider {
      border-block-start: 2px solid rgba(5, 5, 5, 0.06);
      margin: 0px;
    }
  }

  .card-footer {
    margin-top: 16px;
    justify-content: flex-end;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 7;
    background-color: $white;
    padding: 16px 0;
  }

  &-lg {
    padding: 24px;
    gap: 8px;
    border-radius: 25px;
    margin: $fixed-margin;
    box-shadow: 0px 4px 6px -2px #1018280d, 0px 12px 16px -4px #1018281a;

    filter: unset;

    .card-header {
      margin-bottom: 24px;

      &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
      }
    }
  }

  &-noLimit {
    height: fit-content;
    max-height: fit-content;
  }

  &-noMargin {
    margin: unset;
  }

  &-flat {
    filter: none;
    border-radius: 4px;
    gap: 12px;
    box-shadow: none;
    border: 1px solid $token_gray_100;
  }

  &.clear-background {
    .card-header {
      background-color: transparent;
    }
  }
}
