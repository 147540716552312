@import '../../design-system/global.scss';

.shipments-action {
  margin-bottom: 10px;
  padding: 0px 5px;

  &__selector {
    width: 200px;
  }
}

.user-gravatar-wrapper {
  display: flex;
  align-items: center;

  .user-gravatar {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: -10px;
  }
}

.filter-wrapper {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.filters-form-wrapper {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}

.company-text {
  width: 100px;
}

.ant-table-column-sorters {
  .ant-table-column-sorter {
    .ant-table-column-sorter-inner {
      .anticon {
        font-size: 15px;
      }
    }
  }
}

.shipment-id-cell {
  .preview-btn {
    display: none;
  }

  &:hover {
    .preview-btn {
      display: inline;
    }
  }
}

.shipment-wrapper {
  height: 70vh;
  overflow-y: scroll;
  padding-right: 15px;

  .add-user-button {
    svg path {
      fill: $black;
    }
    &:disabled {
      svg path {
        fill: $gray_400;
      }
    }
  }
}
