@import '../../../design-system/global.scss';

.messages-wrapper {
  background: $white;
  /* Shadow/shadow-mid */
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 10px;
  padding: 20px;
}
.messages-wrapper,
.messages-wrapper__fit-content {
  .send-message-wrapper {
    width: 100%;
    display: flex;
    margin-top: 30px;

    .user-gravatar {
      margin-right: 20px;
    }

    .input-wrapper {
      width: 100%;
      display: flex;
      align-items: center;

      .add-message-btn {
        margin-left: 10px;
      }
    }
  }

  .send-message-wrapper {
    width: 100%;
    display: flex;
    margin-top: 30px;

    .user-gravatar {
      margin-right: 20px;
    }

    .input-wrapper {
      width: 100%;
      display: flex;
      align-items: center;

      .add-message-btn {
        margin-left: 10px;
      }
    }
  }

  .message-error-text {
    margin: 10px 0 0 60px;
  }

  .messages-list-wrapper {
    height: 350px;
    overflow-y: auto;
  }
}

.messages-wrapper__fit-content {
  .messages-list-wrapper {
    height: 38vh;
    overflow-y: auto;
  }
}

.mentions-input {
  width: 100%;
  min-height: 36px;
  margin-right: 10px;
  padding: 4px 11px;
  .mentions-input__input {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: $white;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: $blue_gray;
    border-radius: 6px;
    transition: all 0.2s;
    caret-color: $black;
    color: transparent;
  }
  // hide the textarea text and only show the mentions and substrings to style separately
  .mentions-input__highlighter {
    &__substring {
      visibility: visible !important;
      color: $black;
    }
    .mentions__mention {
      background-color: $light_blue;
      color: $blue_900;
      border-radius: 2px;
    }
  }
}

.mentions-input__suggestions {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 0 !important;
  translate: 0 -100%;
}

.mentiomentions-input__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions-input__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  width: 100%;
  .suggestion-item {
    width: 400px;
    display: flex;
    justify-content: space-between;
    span {
      color: $gray_600;
      margin-right: 5px;
    }
  }
}

.mentions-input__suggestions__item--focused {
  background-color: $gray_400;
}

.mentions-input__mention {
  background-color: $gray_600;
}

.file-preview-list {
  flex-direction: column;
  margin: 10px 0 0 60px;
}

.divider-container {
  .ant-divider-horizontal {
    border-top-color: $red_700;
  }

  position: relative;
  .ant-divider-inner-text {
    color: $red_700;
  }
  .message-timestamp {
    background-color: $background_blue;
    color: $foreground-medium;

    border-radius: 10px;
    padding: 2px 10px 2px 10px;
    position: absolute;
    left: 45%;
    z-index: 10;
  }
}
