.invoices-dashbaord-wrapper {
  .invoices-wrapper {
    padding: 20px 0;

    .invoice-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
    }

    .invoice-empty-screen {
      height: calc(100vh - 250px);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
    }

    .pdf-icon-filled {
      font-size: 20px;
    }
    .quoted-amount-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
}
