@import '../../../design-system//global.scss';

.booking-card {
  background: #ffffff;

  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 16px;
  padding: 16px;
  margin: 16px 0px;
  .booking-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;

    color: $purple;
  }

  .bookink-section {
    border-bottom: 1px solid #ccd0d3;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
}
