@import '../../design-system/global.scss';

.comments-wrapper,
.comments-wrapper__fit-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .send-comment-wrapper {
    padding-top: 15px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &.padding-fix {
      padding-left: 35px;
    }

    .comment-inputs {
      flex: 1;
      margin-left: 15px;

      .add-comment-btn {
        margin-top: 15px;
      }
    }

    .comment-mentions-input,
    .comment-mentions-input-top {
      z-index: 10;
      width: 100%;
      min-height: 36px;
      margin-right: 10px;
      padding: 4px 11px;
      &__input {
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        line-height: 1.5714285714285714;
        list-style: none;
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        background-color: $white;
        background-image: none;
        border-width: 1px;
        border-style: solid;
        border-color: $blue_gray;
        border-radius: 6px;
        transition: all 0.2s;
        color: transparent;
        caret-color: $black;
      }
      &__suggestions {
        background-color: red;
        max-height: 300px;
        overflow-x: hidden;
        translate: 0 10%;
        &__item {
          padding: 5px 5px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
          width: 100%;
          .suggestion-item {
            width: 400px;
          }
          &--focused {
            background-color: $gray_400;
          }
        }
      }

      &__highlighter {
        &__substring {
          visibility: visible !important;
          color: $black;
        }
        .mentions__mention {
          background-color: $light_blue;
          color: $blue_900;
          border-radius: 2px;
        }
      }
    }
  }
  .comment-mentions-input-top {
    &__suggestions {
      max-height: 200px;
      overflow-y: auto;
      translate: 0 -100% !important;
      margin-top: 0 !important;
    }
  }
  .comments-list-wrapper {
    max-height: 350px;
    overflow-y: auto;
  }

  .comment-wrapper {
    display: flex;
    margin-top: 15px;

    .comment-item {
      display: flex;
      padding-right: 15px;

      .user-gravatar {
        margin-right: 5px;
      }
    }

    .comment-content {
      display: flex;
      flex-direction: column;

      .info-wrapper {
        display: flex;
        margin-bottom: 5px;

        .user-name,
        .message-date {
          margin-right: 10px;
        }

        .user-name {
          color: $gray_600;
        }

        .message-date {
          color: $gray_400;
        }
      }
    }
  }
}

.comments-wrapper__fit-content {
  height: 45vh;
}
.proposal-comments-header {
  text-align: center;
  background-color: $background-yellow;
}
