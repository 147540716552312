@import '../../design-system/global.scss';
.shipment-tab__title {
  color: $blue_900;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */
}

.shipment-progress-wrapper {
  padding: 16px 0px;
  position: relative;
  .tick-icon {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    svg {
      height: 25px;
      width: 25px;
      fill: $green_900;
      path {
        color: $green_900;
        fill: $green_900;
      }
    }
  }
}

.footer-action-btn {
  width: 100%;
}
