.add-ff-colleague-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  .target-illustration {
    font-size: 165px;
  }

  .forwarder-select {
    min-width: 200px;
    max-width: 340px;
    margin-bottom: 20px;
  }
}
