@import '../../design-system/global.scss';

.timeline-wrapper {
  width: 100%;
  min-height: 146px;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  overflow-x: hidden;

  &:hover {
    overflow-x: auto;
  }

  .timeline-item-wrapper {
    display: flex;

    .item {
      width: 85px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 0 5px;

      .icon {
        font-size: 25px;
      }

      .label {
        height: 30px;
        margin-top: 10px;
        text-align: center;
        font-size: 10px;
        color: $gray_600;
        padding: 0 5px;

        &.active {
          color: $primary;
        }
      }

      .date {
        font-size: 10px;
        color: $gray_600;
        margin-top: 10px;
      }
    }
    .divider {
      width: 35px;
      height: 1px;
      border: 1px solid $gray-600;
      position: relative;
      top: 10px;
      margin: 0 -25px;

      &.active {
        border: 1px solid $green_900;
      }
    }
  }
}
