@import '../global.scss';

.accordion {
  width: 100%;
  margin: 0 auto;

  .panel {
    border: 1px solid $token_gray_200;
    margin-top: 10px;
    border-radius: 8px;

    .panel-header {
      padding: 24px;
      cursor: pointer;
      font-weight: 500;
      font-size: 18px;
      display: flex;
      align-items: center;
    }

    .panel-content {
      padding: 16px 24px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .panel-content.show {
      max-height: fit-content;
      border-top: 1px solid $token_gray_200;
    }

    .panel-footer {
      padding: 16px 24px 24px 24px;
      display: flex;
      justify-content: end;
    }

    &.filled {
      background-color: $white;
      border: 1px solid $token_gray_100;
      border-radius: 4px;

      .panel-content.show {
        border-top: none;
      }
    }
  }
}

.confirm-btn {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
