.reference-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 12ch; /* The line will never be longer than the equivalent length of 12 "0" characters for that font, in that font size */

  &__long {
    max-width: 30ch; /* The line will never be longer than the equivalent length of 30 "0" characters for that font, in that font size */
  }
}
