@import '../../design-system/global.scss';

.description-title-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 200px;

  .ant-typography.title {
    font-weight: bold;
    margin: 0;
    margin-bottom: 5px;
  }

  .description {
    color: $gray_600;
  }
}
