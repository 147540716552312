@import '../../design-system/global.scss';

.description-list-wrapper {
  display: flex;
  align-items: center;
  margin: 15px;
  font-size: 13px;

  .label {
    flex: 1;
    margin-right: 10px;
    color: $gray_600;
  }

  .description {
    flex: 2;
    font-weight: 500;
  }

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;

    .label {
      margin-bottom: 10px;
    }
  }
}
