@import '../global.scss';

.control-card {
  background-color: $token_gray_50;
  border-radius: 4px;
  padding: 16px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  &__main {
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }
}
