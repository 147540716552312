@import '../../../design-system/global.scss';
@mixin commonStyles {
  max-width: 200px;
  font-weight: 400;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.shipment-text {
  display: flex;
  align-items: center;
  gap: 5px;
  text-align: center;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  &--main {
    margin-bottom: 0px !important;
  }

  &--sub > .highest-label,
  &--sub > .highest-value {
    @include commonStyles;
    font-size: 11px;
    color: $foreground-low;
  }

  &--sub > .lowest-value,
  &--sub > .lowest-label {
    @include commonStyles;
    font-size: 13px;
  }

  &--sub > .lowest-value {
    font-weight: bold;
    color: $black;
  }
  &--slug {
    max-width: 60px;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    color: $foreground-low;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &--sub {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $foreground-low;
  }
  &--items {
    max-width: 200px;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    color: $foreground-low;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.quoted-text {
  color: #6f6f6f;
  margin-top: 5px;
  font-size: 15px;
  line-height: 18px;
}
.header-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
  line-height: 18px;
  &--shipment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    line-height: 18px;
    color: blue;
  }
  .country-code {
    margin-top: 5px;
    font-weight: normal;
    color: $foreground-low;
  }
}
.from-to {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shipment-bidding {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .labels,
  .values {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-end;
  }
}
