@import '../../design-system/global.scss';

.shipment-details-wrapper {
  width: 100%;
  padding: 20px;
  border-radius: 8px;

  .shipment-details-tabs {
    > .ant-tabs-nav .ant-tabs-nav-wrap {
      background: $secondary;
      width: 100%;
      height: 60px;
      /* Shadow/shadow-low */
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      border-radius: 2px;

      padding: 0 20px;
    }
  }
}

.shipment-details-loading {
  display: flex;
  height: calc(100vh - 200px);
  justify-content: center;
  align-items: center;
  align-content: center;
}

.tracking-map {
  margin: 0px !important;
  .map-wrapper__container {
    height: 360px !important;
  }
}

.overview-card-wrapper-1 {
  min-height: 360px;
}

.overview-card-wrapper-2 {
  min-height: 470px;
}

.overview-card-wrapper-1,
.overview-card-wrapper-2 {
  .card-header__title {
    font-size: 16px;
  }
}
