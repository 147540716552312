@import '../../../design-system/global.scss';

.proposal-details-wrapper {
  padding: 20px;

  .proposal-details-space-fix {
    width: 100%;
  }

  .proposal-card {
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 16px;
    margin: 20px 0;
    padding: 20px;
  }

  .proposal-tag {
    margin: 0;
    margin-bottom: 10px;
  }

  .header {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .user-gravatar {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }

    .info {
      display: flex;
      align-items: center;

      .title-edit-wrapper {
        display: flex;

        .edit-btn {
          margin-left: 10px;
        }
        .copy-btn {
          color: $blue_900;
        }
      }
    }

    .status {
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .desctiption-content-wrapper {
    .desctiption-content-box {
      margin-top: 5px;
      padding: 14px;
      background: #f7faff;
      /* Foreground/border */
      border: 1px solid $blue_gray;
      border-radius: 4px;
      white-space: pre-line;
    }
  }

  .items-wrapper {
    margin: 20px 0;

    .items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $gray_300;
      padding: 10px;
    }
  }

  .proposal-actions {
    display: flex;
    align-items: center;
    justify-content: center;

    > .ant-btn {
      margin: 0 10px;
    }
  }
}
