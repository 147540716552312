@import '../../design-system/global.scss';
.contract-table {
  .ant-table-expanded-row {
    .ant-table-cell,
    .ant-table-cell:hover {
      background-color: white;
    }
  }

  &--toolbox {
    margin: 0 0 20px 0;
  }
}
.coast-head-collapse {
  background-color: white;

  .ant-collapse-item {
    border: none !important;
    margin: 5px 0;
    .ant-collapse-header {
      background-color: $background_blue;
      border-radius: 8px;
    }
  }

  &--icon {
    color: $purple;
    svg {
      fill: $purple;
      color: $purple;

      path {
        fill: $purple;
        color: $purple;
      }
    }
  }
}

.search-wrapper {
  margin-bottom: 10px;
}

.company-text {
  width: 100px;
}
