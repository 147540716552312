@import '../../../design-system/global.scss';

.file-upload-wrapper {
  padding: 10px;

  .file-dropzone {
    width: 100%;
    height: 200px;
    border: 2px dashed #cccccc;
    border-radius: 4px;
    margin-top: 20px;
    text-align: center;
    flex-direction: column;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .file-upload-footer {
    display: flex;
    justify-content: right;

    .save-file-btn {
      margin-top: 15px;
    }
  }

  .file-table-header {
    background: $gray_200;
    padding: 8px 0;
    margin-bottom: 15px;
    border-radius: 4px;
  }

  .file-item-wrapper {
    margin-bottom: 15px;
    border-bottom: 1px solid $blue_gray;

    .ant-form-item {
      margin-bottom: 15px;
    }
  }

  .action-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
