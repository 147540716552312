@import '../../design-system/global.scss';
.dynamic-delete-order-button {
  margin-top: 30px;
}

.unit-dimensions-checkbox {
  margin: 16px 0px;
}

.shipment-type-checkbox {
  padding: 5px 15px;
  background: $gray_100;
  border: 1px solid $gray_300;
  border-radius: 8px;
}

.units-footer {
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;

  &__wrapper {
    margin: 10px 20px;
    min-width: 120px;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &__amount {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: center;

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &__amount-wrapper {
    padding: 16px;
    background-color: $light-yellow;
    width: fit-content;
    border-radius: 8px;
  }
}

.right-pane {
  &__pdf-viewer {
    height: calc(100vh - 60px);
  }
}

.order-item-title {
  display: flex;
  align-items: center;

  .custom-tag {
    height: 32px;
    padding: 8px 16px 8px 16px;
    border-radius: 15px;
    background-color: $token_red_100;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: $token_red_700;
    margin-left: 30px;
  }
}
