@import '../../../design-system/global.scss';

.api-key-container {
  width: 100%;
  margin: 20px;
  display: flex;

  .api-key-input {
    width: 100%;
    max-width: 400px;
  }

  .api-key-btn {
    margin-top: 40px;
    margin-left: 20px;
  }
}
