@import '../../../design-system/global.scss';

.signup-onboarding-modal-wrapper {
  .signup-onboarding-content {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .icon-box {
    margin-top: 36px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid $token_gray_200;
    border-radius: 8px;
    padding: 12px 8px;
    box-shadow: 0px 1px 1px 0px #0000001f;

    &__icon {
      width: 80px;
      height: 80px;
      border-radius: 12px;
      background-color: $toke_purple_50;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__title {
      text-align: center;
      margin-top: 12px;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  .skip-btn.ant-btn {
    margin: 36px auto 20px;
    display: block;
  }
}
