@import '../../../design-system/global.scss';

.invoice-header-wrapper {
  border-bottom: 1px solid $blue_gray;
.title-col{
  display: flex;

  .title {
    color: $purple;
  }
}
}
