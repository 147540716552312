.decline-modal {
    .ant-modal-body {
      padding: 16px;
    }
    .ant-modal-header{
        .ant-modal-title {
            color: red;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 15px;
        }
    }
    
  }