@import '../../../design-system/global.scss';

.task-wrapper {
  height: 335px;
  overflow-y: auto;
  margin: 0 -15px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.task-item-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 5px;

  .task-item-action-wrapper {
    display: flex;
    margin-top: 5px;
    justify-content: space-between;
  }

  .task-actions {
    display: flex;
    justify-content: flex-end;
  }

  &:nth-child(1) {
    margin-top: 0;
  }

  .name-text {
    color: $gray_600;
    font-size: 14px;
  }

  .assignee-gravatar {
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

  .task-input {
    width: 100%;
    max-width: 28%;
    flex-grow: 1;
    margin-right: 10px;
  }

  .task-date-picker {
    width: 100%;
    min-width: 80px;
    max-width: 18%;
    flex-grow: 1;
    margin-right: 10px;
    height: fit-content;
  }
}

.task-footer {
  margin-top: 20px;
  display: flex;
  min-height: 32px;

  .task-input {
    margin-right: 20px;
  }
}
