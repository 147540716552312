@import '../../../../design-system/global.scss';

.collaborators-table {
  max-height: 400px;
  overflow: auto;
}

.invite-wrapper {
  color: black;
}
.update-access-wrapper {
  color: $gray_600;
}
