.profile-wrapper {
  text-align: center;
  padding: 10px;
  .upload-btn {
    margin-top: 16px;
  }
}

.profile-alert {
  margin: 16px;
}

.save-btn {
  margin-top: 16px;
}

.profile-sider {
  .ant-tabs-content-holder {
    background-color: white;
  }
}
