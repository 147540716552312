.auth-form {
  padding: 0 30px;
  overflow-y: auto;
  height: min-content;
  margin-bottom: 20px;

  &__remember-me {
    justify-content: space-between;
  }

  &__sign-btn,
  &__sign-btn-external {
    width: 100%;
    margin-top: 8px;
    align-items: center;
    justify-content: center;
    display: flex;

    svg {
      margin-right: 10px;
    }
  }
}

.signup-success-message {
  margin: 40px 0;
}
