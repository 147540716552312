@import '../../design-system/global.scss';
$height: 65vh;

.chat-wrapper {
  width: 500px;
  background-color: $white;
  z-index: 99;
  position: fixed;
  bottom: 100px;
  right: 2vw;
  border: 1px solid $blue_gray;
  box-shadow: 12px 24px 24px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;

  .ant-tabs-nav {
    background-color: white;
    height: 60px;
    margin-top: 0;

    .ant-tabs-nav-wrap {
      border-radius: 0px 0px 16px 16px;
      box-shadow: 12px 24px 24px rgba(16, 24, 40, 0.1),
        0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    }
  }
}

.home-container {
  background-color: $gray_50;
  width: 100%;
  height: $height - 5vh;
  border-radius: 16px 16px 0 0;
  overflow: auto;
  position: relative;

  &__header {
    padding: 20px 16px 60px;
    background: linear-gradient(
      180deg,
      $purple 25.01%,
      rgba(73, 97, 246, 0.75) 75.42%,
      rgba(73, 97, 246, 0) 100%
    );

    &--company.ant-typography {
      color: white;
      font-size: 24px;
      margin-top: 0;
      margin-bottom: 20px;
    }

    &--greetings {
      color: white;
      font-weight: 800;
      font-size: 18px;
      margin-bottom: unset !important;
    }

    .send-message-wrapper {
      background: $light-yellow;
      padding: 10px 15px;
      margin: 20px auto 0;
      border-radius: 8px;
    }
  }

  &__suggestions {
    margin-top: -50px;

    .content {
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      border-radius: 8px;
      background-color: white;
      padding: 16px;
      width: 90%;
      margin: 16px;
      height: fit-content;
      overflow-y: auto;

      h5 {
        margin: unset !important;
        line-height: 1 !important;
      }

      .question-wrapper {
        padding: 8px;
        cursor: pointer;

        .question-text {
          width: 70%;
        }
        svg {
          fill: $purple;
        }
      }
    }
  }
}

.messages-container {
  background-color: white;
  width: 100%;
  height: calc($height - 5vh);
  position: relative;
  display: flex;
  flex-direction: column;

  &__header {
    background: $purple;
    width: 100%;
    border-radius: 16px 16px 0px 0px;
    padding: 10px;

    &--title {
      color: white;
    }
  }

  &__content {
    background-color: white;
    width: 100%;
    height: calc(100% - 60px);
    padding: 5px 0;
    overflow-y: scroll;
  }

  &__empty {
    background-color: white;
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__send {
    background-color: white;
    width: 100%;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: end;

    &--input {
      width: 70%;
    }
  }
}
