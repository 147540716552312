.docs-table-wrapper {
  position: relative;
  overflow: scroll;

  .editable-cell-value-wrap {
    min-height: 25px;

    &:empty {
      border: 1px solid #e8e8e8;
      border-radius: 4px;
    }
  }
}

.footer-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  .download-btn {
    background-color: #dae9fe;
    color: #0062ff;
  }
}
