.modal-wrapper {
  .ant-modal-content {
    padding: 24px;
  }

  &__header {
    text-align: center;
    display: flex;

    &__content {
      max-width: 70%;
      margin: 0 auto;
    }

    &__left-control,
    &__right-control {
      min-width: 36px;
    }
  }
}
