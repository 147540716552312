@import '../../../design-system/global.scss';
.booking-details-wrapper {
  border-radius: 8px;
  border: 1px solid $blue_gray;
  background: $white;

  /* Shadow/shadow-mid */
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);

  padding: 10px;
}

.booking-details-alert {
  margin: 10px 0;
}

.action-row {
  padding: 5px;
  > button {
    margin: 5px;
  }
}
