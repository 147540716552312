@import '../../../design-system/global.scss';

@mixin table-cell-nth-child-style($childNumbert) {
  .ant-table-tbody tr,
  .ant-table-thead tr,
  .ant-table-summary tr {
    & > :nth-last-child(#{$childNumbert}) {
      border-left: 1px solid $blue_gray;

      &.ant-table-cell {
        padding-left: 20px !important;
      }
    }
  }

  .ant-table-summary tr {
    .ant-table-cell {
      border-bottom: 1px solid $blue_gray;
    }
  }
}

.invoice-matching-table-wrapper {
  margin-bottom: 10px;

  .icon-wrapper {
    display: flex;
    justify-content: center;
  }

  .cell-edit-input,
  .ant-select-selector {
    display: flex;
    align-items: center;
    height: 27px !important;
    border-radius: 4px;
    width: 100px;

    &.description {
      width: 80%;
    }
  }

  .cell-red-border,
  .cell-red-filled,
  .cell-gray-filled {
    width: 100%;
    display: block;
    padding: 2px 4px;
    border-radius: 4px;
    min-height: 24px;

    &.description {
      width: 80%;
    }
  }

  .cell-red-border {
    border: 1px solid #f94f5d;
  }

  .cell-red-filled {
    background: #f94f5d;
    color: $white;
  }

  .cell-gray-filled {
    background: #e5edf0;
  }

  &.invoice-matching-view {
    @include table-cell-nth-child-style(2);
  }

  &.proposal-matching-view {
    @include table-cell-nth-child-style(4);
  }

  &.save-share-view {
    @include table-cell-nth-child-style(3);
  }
}

.invoice-matching-footer {
  margin-top: 20px;
}

.actual-cost-wrapper {
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-all;
}
