@import '../../../design-system/global.scss';
.search-collaborator-input {
  margin-top: 10px;
  margin-bottom: 10px;
}

.add-sc-wrapper {
  .add-sc-btn {
    color: $blue_900;
    font-weight: 600;
    margin: 10px 0;
  }
}
