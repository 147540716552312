@import '../../design-system/global.scss';

.file-preview-wrapper {
  background: $secondary;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  justify-content: space-between;
  position: relative;

  /* Shadow/shadow-low */
  filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1))
    drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06));

  .view-img {
    width: 100%;
    height: 95%;
    border-radius: 8px 8px 0 0;
    background-color: $blue_gray;
    background-size: cover;
    background-position: center center;
  }

  .file-preview-actions {
    position: absolute;
    right: 5px;

    &.justify {
      justify-content: space-between;
    }

    .view-btn.clear {
      color: $gray_600;
    }

    .edit-btn {
      margin-right: 5px;
    }

    &--edit {
      align-items: center;
      display: flex;
    }

    .file-actions-btn {
      background: rgba(0, 0, 0, 0.65);
      border-radius: 4px;
      margin: 2px;

      svg path {
        fill: #fff;
      }
    }
  }

  .file-preview {
    padding: 10px;
    &__date {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: $foreground-low;
    }

    &__type {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: $foreground-medium;
    }

    &__title {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  &.small {
    .file-preview-actions {
      display: flex;
      justify-content: space-between;
      padding: 5px 10px;

      .view-btn {
        font-size: 10px;
        text-align: center;
      }
    }

    .file-preview {
      padding: 5px;

      &__title {
        font-size: 12px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        max-lines: 1;
        text-overflow: ellipsis;
      }
    }
  }

  &.middle {
    width: 200px;
    height: 250px;

    .view-img {
      height: 80%;
    }

    .file-preview-actions {
      padding: 10px 5px;

      .view-btn {
        font-size: 14px;
      }
    }
  }
}
