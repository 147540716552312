@import '../../../design-system/global.scss';
.settings-header {
  display: grid;
  padding: 10px;
  grid-gap: 7px;

  svg {
    width: 15px;
    transform: translateY(2px);
  }
}

.actions-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
}

.settings-table {
  thead {
    border-radius: 8px !important;
  }
  thead > tr > th {
    background-color: $gray_200 !important;
  }
}

.color-display {
  width: 24px;
  height: 24px;
  border-radius: 5px;
}
