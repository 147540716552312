.booking-title {
  margin: unset;
  font-size: 20px;
}

.booking-header {
  margin-bottom: 10px;
}

.booking-tabs-wrapper {
  height: 70vh;
  overflow-y: scroll;
  padding-right: 15px;
}
