.booking-party-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  .booking-party-content {
    width: 300px;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }

  .booking-party-icon > svg {
    width: 219px;
    height: 213px;
  }
  .share-bp-illustration {
    width: 182px;
    height: 132px;
    background: url('../../../../assets/share-booking-party-illustartion.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
