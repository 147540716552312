@import '../../design-system/global.scss';

.table-description-list-wrapper {
  display: flex;
  align-items: center;
  margin: 15px;

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
  }

  .label {
    flex: 1;
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .description {
    flex: 2;
    color: $gray_600;
  }
}
