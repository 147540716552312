// basics

$black: #000000;
$white: #ffffff;
$white_300: #fefefe; // rename this

$background_medium: #f6f6f6;

$foreground-low: #a1b5bc;
$foreground-medium: #6f6f6f;

$file_link: #111827;

$primary: $black;
$secondary: $white;
// colors
// yellow
$background-yellow: #fffbf0;
$light-yellow: #ffe989;

$card-success: #f0f9f4;
$card-failure: #fbecee;
$card-primary: #f6f7fb;

// gray
$gray_600: #6f6f6f;
$gray_500: #8c8c8c;
$gray_400: #bfbfbf;
$gray_300: #d9d9d9;
$gray_200: #f1f2f4;
$gray_100: #f5f5f5;
$gray_50: #f7f7f7;
$gray_45: #00000073;

// green
$green_900: #46b96b;
$green_200: #f0f9f4;

// blue
$blue_100: #e5eeff;
$light_blue: #f5f7fb;
$blue_gray: #ccd0d3;
$blue: #2296f2;
$blue_900: #0062ff;
$blue_selected: #1677ff;
$background_blue: #f7faff;

// purple
$purple: #4961f6;
$purple_600: #5a31ff;
$purple_100: #acb8ff;
$purple_50: #f7f4fd;
$background_purple: #f5f3ff;
$forground_purple: #4c1d95;

// red
$red_900: #d73d51;
$red_700: #f94f5d;
$red_200: #fdf1f1;
$red_300: #f9cccc;

//
$fixed-margin-sm: 8px;
$fixed-margin: 16px;
$fixed-margin-lg: 32px;

// NEW DESIGN PALLETS
$token_secondary: #6b7280;

$toke_purple_50: #f5f3ff;
$toke_purple_900: #4c1d95;

$token_gray_50: #f9fafb;
$token_gray_100: #f3f4f6;
$token_gray_200: #e5e7eb;

$token_red_100: #fee2e2;
$token_red_800: #991b1b;
$token_red_700: #b91c1c;
