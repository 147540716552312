@import '../../../design-system/global.scss';
.contracts-container {
  padding: 16px 8px;
}
.search-box-container {
  border-radius: 24px;
  background: linear-gradient(91deg, #003283 0.23%, $blue_900 99.73%);
  padding: 28px 16px 16px 16px;

  &__title {
    color: white;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 114.286% */
  }

  .btn-search {
    margin-top: 30px;
  }

  .form-item-wrapper {
    margin: 12px;
    .ant-form-item-label label {
      color: white !important;
      font-weight: 600;
    }
  }
}
.freight-details-container {
  /* Shadow/shadow-high */
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.05),
    0px 12px 16px -4px rgba(16, 24, 40, 0.1);
  background: white;
  padding: 8px;
  border-radius: 8px;
  .select-btn {
    width: 100%;
  }
}

.contract-collapse {
  border-radius: 16px;
  background: $white;

  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);

  .horiz-line {
    margin: 10px 0px;
  }

  .vert-line {
    height: 100px;
  }

  .carrier-text {
    color: $purple;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  .book-btn {
    &.ant-btn {
      border-radius: 8px;
      border: 1px solid $blue_900;
      background: $blue_900;

      /* drop-shadow/button-primary */
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

      height: fit-content;
      padding: 8px 16px;
    }
    &__text {
      color: $white;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
    }
  }
}
.book-shipment-wrapper {
  border-left: solid 1px $gray_400;
  margin-left: 10px;
  padding-left: 10px;
}

.empty-contracts {
  margin: 100px;
}
