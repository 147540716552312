@import '../global.scss';

$width: 100vw;
.indicator-holder {
  background-color: $white;
  display: flex;
  align-items: center;

  padding: 16px;
  gap: 4px;
  border-radius: 8px;
  width: 19%;

  cursor: pointer;

  //
  filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1))
    drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06));

  &__icon {
    margin-right: 8px;
  }

  .value-text {
    margin: unset;
    margin-bottom: 8px;
  }

  .title-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $gray_600;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}
